import React from "react";
import Slider from "../Components/Slider";
import Serves from "../Components/Serves";
import Footer from "../Components/Footer";
import CallToAction from "../Components/CallToAction";
import Header from "../Components/Header";

const Home = () => {
	return (
		<div>
			<Header/>
			<Slider />
			<div className="container">
			<Serves />
			</div>
			<Footer />
			<CallToAction />
		</div>
	);
};

export default Home;
