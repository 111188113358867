import travelslide1 from "./Assets/slider-travel-1.jpg";
import serve1 from "./Assets/serve1.png";
import serve2 from "./Assets/serve2.png";
import serve3 from "./Assets/serve3.png";
import serve4 from "./Assets/serve4.png";
import serve5 from "./Assets/serve5.png";
import slide1 from './Assets/new.jpg'
import travelServe2 from "./Assets/full2.jpg";

export const slider = [
	{
		id: 1,
		src: travelServe2,
		desc: "احجز تذكرتك الان بأفضل الاسعار",
	},
	{
		id: 2,
		src: slide1,
		desc: "احجز تذكرتك الان بأفضل الاسعار",
	},
];

export const services = [
	{
		id: 1,
		src: serve1,
		title: "سريلانكا -كولومبور",

		desc: "  اكتشف عالمنا الجديد انضم إلى رحلتنا السحرية إلى أرقى وجهات السفر في العالم نحن نقدم تجارب سفر لا تُنسى الرحلة تشمل 4 أيام شامل الطيران و الاقامه بالفندق  ووجبه أفطار وشامل الانتقالات من الفندق للمطار و سعر الفرد يبدأ من 2480 درهم",
	},

	{
		id: 2,
		src: serve2,
		title: "اندونسيا",

		desc: "  اكتشف عالمنا الجديد انضم إلى رحلتنا السحرية إلى أرقى وجهات السفر في العالم نحن نقدم تجارب سفر لا تُنسى الرحلة تشمل 4 أيام شامل الطيران و الاقامه بالفندق  ووجبه أفطار وشامل الانتقالات من الفندق للمطار و سعر الفرد يبدأ من 3190 درهم",
	},
	{
		id: 3,
		src: serve3,
		title: "جورجيا",

		desc: "  اكتشف عالمنا الجديد انضم إلى رحلتنا السحرية إلى أرقى وجهات السفر في العالم نحن نقدم تجارب سفر لا تُنسى الرحلة تشمل 4 أيام شامل الطيران و الاقامه بالفندق  ووجبه أفطار وشامل الانتقالات من الفندق للمطار و سعر الفرد يبدأ من 2245 درهم",
	},
	{
		id: 4,
		src: serve4,
		title: "اذبريجان -باكو",

		desc: "  اكتشف عالمنا الجديد انضم إلى رحلتنا السحرية إلى أرقى وجهات السفر في العالم نحن نقدم تجارب سفر لا تُنسى الرحلة تشمل 4 أيام شامل الطيران و الاقامه بالفندق  ووجبه أفطار وشامل الانتقالات من الفندق للمطار و سعر الفرد يبدأ من 1695 درهم",
	},
	{
		id: 5,
		src: serve5,
		title: "المالديف",

		desc: "  اكتشف عالمنا الجديد انضم إلى رحلتنا السحرية إلى أرقى وجهات السفر في العالم نحن نقدم تجارب سفر لا تُنسى الرحلة تشمل 4 أيام شامل الطيران و الاقامه بالفندق  ووجبه أفطار وشامل الانتقالات من الفندق للمطار و سعر الفرد يبدأ من 8635 درهم",
	},
];
