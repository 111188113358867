import React from "react";
import { Navigation, Pagination, A11y, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../Assets/logo4.png";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { EffectFade } from "swiper";
import { slider } from "../data";

const Slider = () => {
	return (
		<div>
			<Swiper
				modules={[
					Navigation,
					Pagination,
					Scrollbar,
					A11y,
					EffectFade,
					Autoplay,
				]}
				slidesPerView={1}
				autoplay={{ delay: 2500 }}
				effect="fade"
			>
				{slider.map((slider, index) => {
					return (
						<SwiperSlide key={index}>
							<div className="position-relative">
								<div
									style={{
										backgroundImage: `url(${slider.src})`,
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
										width: "100%",
										height: "70vh",
									
									}}
									className="background-img"
								>
									<div className="contentSlider">
										<p className="textSlider">{slider.desc}</p>
										<a href="tel:+97165334090" className="contact ">
											اتصل بنا
										</a>
									</div>
								</div>
								<div className="overlay"></div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Slider;
