import React, { useEffect, useState } from "react";
import logo from "../Assets/logo4.png";

const Header = () => {
	const [activeNavItem, setActiveNavItem] = useState(null);

	const handleNavClick = (event, index) => {
		setActiveNavItem(index);
	};

	return (
		<section className={`header-container navbar-custom 			}`}>
			<nav className="navbar navbar-expand-lg pe-md-5   pe-sm-4  ">
				<div className="container">
					<a className="" href="/">
						<img className="logo" src={logo} />
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasNavbar"
						aria-controls="offcanvasNavbar"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					{/* canvas Menu */}
					<div
						style={{ zIndex: "10000" }}
						className="zIndexMenu-canvas"
						class="offcanvas offcanvas-start"
						tabIndex="-1"
						id="offcanvasNavbar"
						aria-labelledby="offcanvasNavbarLabel"
					>
						<div className="offcanvas-header">
							<img className="logo w-75 h-auto" src={logo} />
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="offcanvas"
								aria-label="Close"
							></button>
						</div>
						<div className="offcanvas-body  d-flex justify-content-center  ">
							<ul className="navbar-nav  align-items-center ">
								<li className=" mt-md-3 mt-lg-0 nav-item ">
									<a
										className={`activeColor ${
											activeNavItem === 0 ? "activeColor" : ""
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 0)}
										aria-current="page"
										href="/"
									>
										الرئيسية
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3  mt-md-3 mt-lg-0 ">
									<a
										className={`${
											activeNavItem === 2 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 2)}
										href="#products"
										id="navbarDropdown"
									>
										الرحلات
									</a>
								</li>

								<li className="mt-sm-3  mt-xs-3 mt-md-3 mt-lg-0 ">
									<a
										href="tel:+97165334090"
										className={`${
											activeNavItem === 5 ? "activeColor" : "NonactiveColor"
										} ms-5 `}
										onClick={(e) => handleNavClick(e, 5)}
									>
										اتصل بنا
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</section>
	);
};

export default Header;
