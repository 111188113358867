import React from "react";
import locate from "../Assets/locate.png";
const Footer = () => {
	return (
		<div className="pt-5">
			<ul className=" d-flex pt-5 justify-content-center align-items-center ">
				<li className=" d-flex  gap-1 ">
					<img className="icon-location " loading="lazy" src={locate} />
					<a
						target="_blank"
						href="https://www.google.com/maps/place/Sky+Away+Travel+%26+Toursim/@25.404074,55.4804669,17z/data=!3m1!4b1!4m16!1m9!3m8!1s0x3e5f5924d26d3617:0xbe8f063d89bee314!2sSky+Away+Travel+%26+Toursim!8m2!3d25.4040692!4d55.4830418!9m1!1b1!16s%2Fg%2F11twqf66k6!3m5!1s0x3e5f5924d26d3617:0xbe8f063d89bee314!8m2!3d25.4040692!4d55.4830418!16s%2Fg%2F11twqf66k6?entry=ttu"
						className="location"
					>
						مبنى بوليفارد التجاري A - الدور الثاني - 205 - ش الحسن البصري -
						الجرف 2 - عجمان الامارات العربية المتحدة
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
