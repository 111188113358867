import React from "react";
import { services } from "../data";
import logo from "../Assets/logo.png";
const Serves = () => {
	return (
		<div id="products" className=" text-center  serves">
			<h1 className="mb-4">الرحلات</h1>
			<p>اجمل وامتع الرحلات لاسيا وأوروبا</p>

			<div className="row  justify-content-center ">
				{services.map((item) => (
					<div className="col-sm-12 col-lg-5">
						<div className="serveContainer ">
							<img src={item.src} className="serveImg" loading="lazy" />
						</div>

						<div className="py-1 text-center contentCard">
							<h5 className="fw-bold">{item.title}</h5>
							<p className="text-serves px-1">{item.desc}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Serves;
